<!-- 招生系统基础配置 -- 中考科目管理 -- 列表 -->
<template>
	<div>
		<list-template :current-page="page" :loading="loading" :table-config="tableConfig" :table-data="tableData" :total="total" has-add @onAdd="$router.push('./add')" @onChangePage="handleCurrentChange" @onHandle="handleEdit"
										:search-config="searchConfig" @onReset='reset' @onSearch="onSearch"></list-template>

		<!-- 历史记录 -->
		<h-dialog :historyDialogShow="historyDialogShow" :historyList="historyList" @close="historyDialogShow = false"></h-dialog>
	</div>
</template>

<script>
//引入ajax接口管理层的接口函数
import {mapState} from "vuex";
import ListTemplate from "@/components/pages/List.vue"

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  components: {ListTemplate},
	data() {
		return {
			loading: true,
			tableData: [],
			//总条数
			total: 0,
			pageCount: 0,
			tableConfig: [
				{
					prop: "school",
					label: "报名学校"
				},
				{
					prop: "area_text",
					label: "报名区域"
				},
				{
					prop: "rank_name",
					label: "档位名称"
				},
				{
					prop: "type_text",
					label: "用户分类"
				},
				{
					prop: "creator",
					label: "创建人"
				},
				{
					prop: "handle",
					label: "操作",
					width: "80rem",
					handle: true,
					render() {
						return ["编辑"]
					}
				},
			],
			historyDialogShow: false,
			historyList: [],
			search: null,
			searchConfig:[
				{
					prop:"province",
					tag: 'select',
					placeholder:"查询省份",
					label: 'address_name',
					value: 'address_code',
					options: [],
					change: (e,s) => {
						this.searchConfig[1].options = [];
						this.searchConfig[2].options = [];
						s.city = '';
						s.area = '';
						this.getCity(e)
					}
				},
				{
					prop:"city",
					tag: 'select',
					placeholder:"查询城市",
					label: 'address_name',
					value: 'address_code',
					options: [],
					change: (e,s) => {
						this.searchConfig[2].options = [];
						s.area = '';
						this.getArea(e)
					}
				},
				{
					prop:"area",
					tag: 'select',
					placeholder:"查询区/县",
					label: 'address_name',
					value: 'address_code',
					options: [],
				},
				{
					prop:"school",
					placeholder:"搜索学校名称",
				},
			],
		}
	},
	activated() {
		this.getData()
	},
	created() {
		this.$store.commit("setPage", 1);
		this.getData()
		this.getProvince()
	},
	computed: {
		...mapState(["page"])
	},
	methods: {
		onSearch(val){
			this.$store.commit('setPage', 1);
			this.search = val;
			this.getData(val)
		},
		reset(){
			this.searchConfig[1].options = []
			this.searchConfig[2].options = []
		},
		getData() {
			this.loading = true;
			let params = {...this.search, page: this.page}
			this.$_register('/api/recruit/scholarship/index', {params}).then(res => {
				let data = res.data.data
				this.tableData = data.list
				this.total = data.page.total
			}).finally(() => this.loading = false);
		},

		handleEdit(row) {
			this.$router.push('./edit?id=' + row.id)
		},

		// 当前页改变触发
		handleCurrentChange(val) {
			//当前页赋值给currentPage
			this.$store.commit("setPage", val);
			//拉取数据
			this.getData()
		},
		getProvince(){
			this.$_register('/api/recruit/common/area',{params:{address_code: '000000'}}).then(res => {
				this.searchConfig[0].options = res.data.data
			})
		},

		getCity(e){
			this.$_register('/api/recruit/common/area',{params:{address_code: e}}).then(res => {
				this.searchConfig[1].options = res.data.data
			})
		},

		getArea(e){
			this.$_register('/api/recruit/common/area',{params:{address_code: e}}).then(res => {
				this.searchConfig[2].options = res.data.data
			})
		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep .history-dialog {
	width: fit-content !important;
	overflow-y: auto;
	min-width: 900rem !important;
	max-width: 1600rem !important;
	max-height: 71vh !important;
}

.role-list {
	padding-top: 24rem;
}

.button {
	margin-left: 34rem;
	margin-bottom: 30rem;
	box-sizing: border-box;
	width: 90rem;
	height: 34rem;
	opacity: 1;
	border-radius: 4rem;
}

// }
.table {
	margin-left: 32rem;
}



::v-deep .el-table--enable-row-transition .el-table__body td.el-table__cell {
	text-align: left;
	font-size: 14rem;
	font-weight: normal;
	height: 48rem;
	box-sizing: border-box;
	color: #333333;
}

.header {
	position: fixed;
	top: 120rem;
	left: 300rem;
	font-size: 16rem;
	font-weight: bold;
	line-height: 24rem;
	color: #333333;
	letter-spacing: 3.5rem;
}

.el-button--text {
	margin-right: 40rem;
	font-size: 14rem;
	font-weight: normal;
	color: #3491fa;
}
</style>
